<template>
  <div class="Silver_Menu">
    <v-app-bar
      :color="$vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor"
      :dark="getThemeMode.appBarColor != 'white' ? true : false"
      app
      class="px-sm text-left shadow-sm"
      flat
      height="60"
    >
      <!-- <v-app-bar-nav-icon
        v-ripple="{ class: 'primary--text' }"
        @click="toggleVerticalSidebarDrawer"
      /> -->
      <!-- <vue-navigation-bar class="mt-0" :options="navbarOptions">
      </vue-navigation-bar> -->
      <v-progress-linear
        :active="getThemeMode.isLoading"
        :indeterminate="getThemeMode.isLoading"
        absolute
        bottom
        color="primary"
      />

      <!-- <v-toolbar-title>Egret Vue</v-toolbar-title> -->

      <v-spacer />

      <!-- <v-badge
        bordered
        overlap
        v-if="
          polling.currentState &&
          polling.currentState.name == 'Conectado - En atención' &&
          callData &&
          callData.extraFields &&
          callData.extraFields.Videollamada
        "
        color="red"
        offset-x="20"
        offset-y="20"
      >
        <v-btn icon @click="openVideoCall">
          <v-icon>mdi-video</v-icon>
        </v-btn>
      </v-badge> -->
      <div class="flexdk-version">
        <template>
          <div
            class="text-center statusText"
            v-if="
              silver &&
              silver.menu &&
              silver.menu.showDoctorPanel &&
              pollingData.currentState
            "
          >
            <!-- <b>Estado:</b> {{ pollingData.currentState.name.split(" - ")[0] }} -->
            <b>Estado:</b>
          </div>

          <div
            class="align-baseline flex-wrap ContainerCards mr-3"
            v-if="
              silver &&
              silver.menu &&
              silver.menu.showDoctorPanel &&
              pollingData.currentState
            "
          >
            <v-select
              :items="optionsSelectInputState"
              item-text="name"
              v-model="selectOptionState"
              @change="handleChangeState"
              :disabled="loadingState || loadingChangeState"
              class="p-0"
              style="padding: 0; max-width: 250px; width: 100%; height: 32px; z-index: 900008;"
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  v-show="'Conectado - En atención' !== item.value"
                >
                  <v-list-item-title
                    :id="attrs['aria-labelledby']"
                    v-text="item.value || item.name || item"
                  ></v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </div>

          <!-- <div
            class="text-center cardiology"
            v-if="silver && silver.menu && silver.menu.showDoctorPanel"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="
                    background: none;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    text-transform: capitalize;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!!callData"
                >
                  {{ serviceSelected || "Servicio" }}
                  <i class="mdi mdi-chevron-down"></i>
                </v-btn>
              </template>
              <v-list max-height="400">
                <v-list-item
                  class="dk-heigth"
                  v-for="(item, index) in services"
                  :key="index"
                >
                  <v-list-item-title
                    style="font-size: 0.9rem; cursor: pointer"
                    @click="() => handleChangeService(item)"
                    ><i class="mdi mdi-circle-medium"></i>
                    {{ item.name }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div> -->

          <div class="text-center">
            <i
              v-show="showRecorderIcon"
              class="mdi mdi-video"
              :class="
                mediaRecorder && mediaRecorder.state == 'recording'
                  ? 'redRecorder'
                  : 'grayRecorder'
              "
            ></i>
          </div>

          <div class="text-center">
            <v-menu offset-y z-index="900008">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="
                    background: none;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    text-transform: capitalize;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!!callData"
                >
                  {{ defaultBranch ? defaultBranch.label : "No hay" }}
                  <i class="mdi mdi-chevron-down"></i>
                </v-btn>
              </template>
              <v-list max-height="400">
                <v-list-item
                  class="dk-heigth"
                  v-for="(item, index) in branchesList"
                  :key="index"
                >
                  <v-list-item-title
                    style="font-size: 0.9rem; cursor: pointer"
                    @click="() => handleChangeSelected(item)"
                    ><i class="mdi mdi-circle-medium"></i>
                    {{ item.label }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <v-menu offset-y z-index="900008">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="
                background: none;
                outline: none;
                border: none;
                box-shadow: none;
                outline: none;
                text-transform: capitalize;
              "
              v-bind="attrs"
              v-on="on"
              class="ml-3"
            >
              <v-list-item-avatar
                class="mr-0"
                min-width="30"
                height="30"
                width="30"
              >
                <v-img :src="defaultUser" />
              </v-list-item-avatar>
              <v-list-item
                two-line
                style="min-height: 40px; padding-left: 10px; padding-right: 0px"
              >
                <v-list-item-title class="text-16 mb-0 font-weight-regular">{{
                  userData
                }}</v-list-item-title>
                <!-- <v-divider class="d-block" /> -->
              </v-list-item>
              <i class="mdi mdi-chevron-down"></i>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item two-line style="min-height: 40px">
              <v-list-item-title class="text-16 mb-1 font-weight-regular"
                ><strong>{{ userLabel }}:</strong>
                {{ userData }}</v-list-item-title
              >
            </v-list-item> -->
            <v-list-item>
              <v-list-item-title class="mt-n1">
                <!-- <v-icon class="text-18 mr-2">
                    mdi-card-account-mail-outline
                  </v-icon> -->
                <!-- <v-btn small icon class="mr-2">
                    <v-icon small>mdi-cog</v-icon>
                  </v-btn>
                  <v-btn small icon class="mr-2">
                    <v-icon small>mdi-email</v-icon>
                  </v-btn> -->
                <v-btn
                  @click="logoutUser"
                  :loading="loading"
                  :disabled="!!callData"
                >
                  Cerrar sesión
                  <v-icon small class="ml-2">mdi-logout-variant</v-icon>
                </v-btn>
                <!-- <v-icon class="text-18 mr-2">mdi-inbox-multiple-outline</v-icon> -->
                <!-- <v-icon class="text-18 mr-2">mdi-home-edit-outline</v-icon> -->
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-list-item-title class="version-dk"
          ><strong>Versión:</strong> {{ version }}</v-list-item-title
        >
      </div>

      <!-- <v-btn icon @click="searchDrawer = !searchDrawer">
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->

      <!-- <v-chip pill class="transparent py-5" @click="userDrawer = !userDrawer">
        Hi, Watson
        <v-avatar class="ml-2">
          <v-img src="@/assets/images/faces/1.jpg"></v-img>
        </v-avatar>
      </v-chip> -->
    </v-app-bar>
    <!-- userDrawer -->
    <v-navigation-drawer
      v-model="userDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350"
    >
      <user-drawer>
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="userDrawer = !userDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button
            @click.native="logoutUser"
            text="Logout"
            block
            bg-color="primary lighten-5 primary--text"
            icon-name="mdi-logout"
          />
        </div>
      </template>
    </v-navigation-drawer>

    <!-- searchDrawer -->
    <v-navigation-drawer
      v-model="searchDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="380"
    >
      <search-drawer>
        <template v-slot:searchDrawerCloseButton>
          <v-btn icon color @click.stop="searchDrawer = !searchDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </search-drawer>
    </v-navigation-drawer>

    <v-snackbar v-model="snackbar">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import defaultUser from "@/silver/assets/img/default-avatar.png";

export default {
  name: "MenuCenters",
  data() {
    return {
      vModelMenusCenter: "",
      userDrawer: false,
      defaultUser,
      notificationDrawer: false,
      searchDrawer: false,
      navbarOptions: {
        elementId: "main-navbar",
        isUsingVueRouter: true,
        mobileBreakpoint: 992,
        brandImagePath: "./",
        brandImageAltText: "brand-image",
        collapseButtonOpenColor: "#661c23",
        collapseButtonCloseColor: "#661c23",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "Main Navigation",
        tooltipAnimationType: "shift-away",
        menuOptionsLeft: [],
      },
      inCall: true,
      loading: false,
      version: 0,
      snackbar: false,
      text: "",

      showRecorderIcon: false,
      branchesSelected: [],
      serviceSelected: "Servicio",

      optionsSelectInputState: [],
      timerAtentionTime: null,
      loadingChangeState: null,
      selectOptionState: "",
    };
  },
  components: {
    UserDrawer: () => import("../../layouts/common-drawer/UserDrawer.vue"),
    SearchDrawer: () => import("../../layouts/common-drawer/SearchDrawer.vue"),
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapState({
      branches: (state) => state.branchesSilver.branchesData,
      selectedBranch: (state) => state.branchesSilver.selectedBranch,
      profileData: (state) => state.profileSilver.profileData,
      callData: (state) => state.loggedUserSilver.callData,
      mediaRecorder: (state) => state.loggedUserSilver.mediaRecorder,
      environmentData: (state) => state.environmentSilver.environment,
      silver: (state) => state.environmentSilver.environment.silver,
      services: (state) => state.doctorPanel.services,
      pollingData: (state) => state.pollingWorkplaceSilver.polling,
      loadingState: (state) => state.loggedUserSilver.loadingState,
      loggedUser: (state) => state.loggedUserSilver.loggedUserData,
    }),
    defaultBranch() {
      return this.selectedBranch
        ? this.selectedBranch
        : this.branches?.defaultBranch
        ? this.branches?.defaultBranch
        : this.branches[0];
    },
    branchesList() {
      return this.branches?.branches;
    },
    userData() {
      if (!this.profileData) return "";
      return `${this.profileData.user?.firstName || ""} ${
        this.profileData.user?.lastName || ""
      }`;
    },
    userLabel() {
      return this.profileData?.user_label;
    },
    verifyExtrafieldsVideoCall() {
      return this.callData?.extraFields?.Videollamada;
    },
    polling: function () {
      return this.$store.state.pollingWorkplaceSilver.polling;
    },
  },
  watch: {
    branches(val) {
      if (!val) return;
      this.navbarOptions.menuOptionsLeft = [
        {
          type: "link",
          text: (val.defaultBranch ? val.defaultBranch.label : "") || "",
          // iconLeft: '<i class="mdi mdi-view-dashboard"></i>',
          subMenuOptions:
            val.branches.map((branch) => ({
              type: "link",
              text: branch.label,
              path: { name: "Home", params: { branch } },
              iconLeft: '<i class="mdi mdi-circle-medium"></i>',
            })) || [],
        },
      ];
    },
    callData(val) {
      setTimeout(() => {
        if (!val) return;
        if (!this.verifyExtrafieldsVideoCall) return;
        if (this.$root.autoCall && !this.$root.acceptTurnAuto) {
          return;
        }
        this.toggleVerticalSidebarDrawer(false);
        this.openVideoCall();
      }, 1000);
    },
    defaultBranch(val) {
      if (val === this.branches?.defaultBranch) {
        const selectedBranch = JSON.parse(
          localStorage.getItem("selectedBranch")
        );
        if (selectedBranch) {
          this.changeSelectedBranch(selectedBranch);
        }
      }
    },
    selectedBranch(val) {
      setTimeout(() => {
        if (this.silver && this.silver.recorders) {
          let recorders = Array.isArray(this.silver?.recorders)
            ? this.silver?.recorders
            : Object.keys(this.silver?.recorders).map((r) => parseInt(r));
          this.showRecorderIcon = recorders.includes(val.id);
        } else this.showRecorderIcon = false;
      }, 1500);

      // default branch
      this.branchesSelected = this.selectedBranch
        ? [this.selectedBranch.id]
        : this.branches?.defaultBranch
        ? [this.branches?.defaultBranch.id]
        : [this.branches[0].id];
    },
    // selectedBranch(val) {
    //   if (val === this.branches.defaultBranch) {

    //   }
    // }
    branchesSelected(data) {
      this.setBranches(data);
    },

    "loggedUser.worker"(val_w) {
      if (!val_w) return;
      this.profile = val_w.workerProfile.name;
      this.optionsSelectInputState = this.handlerStateAgentToSelect(val_w);
    },

    pollingData(v) {
      this.selectOptionState = v.currentState;
      if (v.currentState?.name == "Desconectado") this.logoutUser();
    },
  },
  created() {
    let userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      if (userInfo.token) {
        window.token = userInfo.token;
      } else {
        return this.$router.push("/sessions/login");
      }
    }
    // window.selectedBranch = this.branches.defaultBranch;
    this.getAllData();
    this.version = process.env.PACKAGE_VERSION;
    let service = this.$route.query?.service;
    if (service)
      this.serviceSelected = this.services.find((s) => s.code == service)?.name;
    this.setDoctorService(service);
  },
  methods: {
    ...mapActions([
      "changeVerticalSidebarDrawer",
      "signOut",
      "branchGetAction",
      "changeSelectedBranch",
      "getWorkstationTurn",
      "videoChange",
      "setBranches",
      "setDoctorService",
      "updateStateAction",
    ]),
    toggleVerticalSidebarDrawer(value) {
      this.changeVerticalSidebarDrawer(value);

      // this.$emit("update:mini-variant");
      // console.log("check");
    },
    async logoutUser() {
      if (this.callData) {
        this.text =
          "No puedes cerrar sesión mientras estes atendiendo un turno";
        return (this.snackbar = true);
      }

      this.loading = true;
      await this.signOut();
      this.loading = false;
      this.$router.push({ path: "/sessions/login" });
    },
    openVideoCall() {
      this.videoChange(true);
    },
    handleChangeSelected(objectBranch) {
      // window.selectedBranch = objectBranch;
      if (this.$route.path !== "/home/") this.$router.push({ path: "/home/" });
      this.changeSelectedBranch(objectBranch);
      localStorage.setItem("selectedBranch", JSON.stringify(objectBranch));
    },
    handleChangeService(service) {
      // window.selectedBranch = service;
      // if(this.$route.path.includes("doctorPanel")){
      this.$router.push({ query: { service: service.code } });
      this.serviceSelected = service.name;
      this.setDoctorService(service.code);
      // }
    },
    async getAllData() {
      await this.branchGetAction();
    },
    handlerStateAgentToSelect(val) {
      if (val.worker) {
        return val.worker?.workerProfile.possibleStates?.map((state) => ({
          name: state.name,
          value: state.name,
          id: state.id,
        }));
      } else {
        return val.workerProfile?.possibleStates?.map((state) => ({
          name: state.name,
          value: state.name,
          id: state.id,
        }));
      }
    },

    handleChangeState(val) {
      // enable change work station
      this.$root.enableChangeWorkStation = true;

      // date of change status
      this.dateLastChangedState = new Date();

      // Change during call
      if (this.callData) {
        this.loadingChangeState = true;
        this.loadingChangeStateTimer = setTimeout(() => {
          this.loadingChangeState = false;
          clearTimeout(this.loadingChangeStateTimer);
        }, 1000);
        this.changeState = true;
        if (typeof this.selectOptionState == "object")
          this.objectNewState = this.selectOptionState;
        else
          this.objectNewState = this.optionsSelectInputState.find(
            (valResponse) => valResponse.name === this.selectOptionState
          );
        this.snackbar = true;
        this.text = "Se realizará el cambio de estado al terminar la llamada";
        return;
      }

      // search state selected
      const filteredResponse = this.optionsSelectInputState.find(
        (valResponse) => valResponse.name === val
      );

      this.restartTimer();
      if (filteredResponse) {
        this.updateStateAction({
          id: filteredResponse.id,
          name: filteredResponse.name,
        });
        // this.selectOptionState = filteredResponse;
      }

      // clearInterval(this.timerPolling);
      clearInterval(this.timer2);
    },
    restartTimer() {
      // for (var i = 1; i < (this.timerPolling + 10); i++) {
      //   clearInterval(i);
      // }
      clearInterval(this.timerAtentionTime);
      this.counterTimerPolling = 0;
      // this.pollingActive = false;
      // this.$root.acceptTurnAuto = false;
    },
  },
};
</script>

<style lang="scss">
.tippy-box[data-theme~="light"] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.grayRecorder {
  color: gray;
  fill: gray;
  font-size: 1.5rem;
  margin-right: 1rem;
}

.redRecorder {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: rgb(224, 84, 84);
  fill: rgb(224, 84, 84);
}

.vnb {
  background: transparent !important;
  &__menu-options {
    margin-top: 3px !important;
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: #000 !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }
  &:after {
    content: "\F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }
  svg {
    display: none !important;
  }
}

.version-dk {
  font-size: 12px;
  margin-left: 10px;
}

.overflow-y-auto {
  overflow-y: auto;
  padding: 0;
}

.dk-heigth {
  min-height: auto; /*Maybe this is the problem*/
  padding: 10px;
  color: white;
}

.flexdk-version {
  display: flex;
  align-items: center;
}

.statusText {
  display: flex;
  align-items: center;
  margin: 0 1rem;

  b {
    margin-right: 0.5rem;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}

.cardiology {
  margin-right: 1rem;
}
</style>
